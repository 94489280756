<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h5 class="text-muted">My Total Assets</h5>
                <h2 class="text-primary"><i class="fa fa-dollar-sign"/><span
                    v-if="account">{{ total_asset | to2DecimalPlace }}</span>
                  <span v-else>Loading</span>
                </h2>
                <small v-if="account"><span>Referral: ${{ account.investors_account.referral_total_balance | to2DecimalPlace }}</span>
                  + <span>Wallet Balance: ${{ account.investors_account.wallet_total_balance | to2DecimalPlace }}</span>
                  = <span class="text-success font-15"> <i
                      class="fa fa-dollar-sign"/>{{ account.investors_account.wallet_total_balance | to2DecimalPlace }}</span></small>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6">
                <router-link to="/recharge" class="btn btn-sm btn-outline-info btn-block"><i class="fa fa-plus"/>
                  Recharge
                </router-link>
              </div>
              <div class="col-6">
                <router-link to="/invest" class="btn btn-sm btn-outline-success btn-block"><i
                    class="fa fa-money-bill-alt"/> Invest
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>


    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-6 text-center">
                <div class="avatar-sm rounded-circle bg-icon-primary ml-auto mr-auto">
                  <i class="mdi mdi-face-profile font-24 avatar-title text-white"></i>
                </div>
                <h5 class="text-muted">Personal Information</h5>
              </div>
              <div class="col-md-4 col-6 text-center">
                <router-link to="/recharge-history">
                  <div class="avatar-sm rounded-circle bg-icon-success ml-auto mr-auto">
                    <i class="mdi mdi-table-of-contents font-24 avatar-title text-white"></i>
                  </div>
                  <h5 class="text-muted">Recharge Records</h5>
                </router-link>
              </div>
              <div class="col-md-4 col-6 text-center">
                <router-link to="/withdrawal-history">
                  <div class="avatar-sm rounded-circle bg-icon-pink ml-auto mr-auto">
                    <i class="mdi mdi-chat-alert font-24 avatar-title text-white"></i>
                  </div>
                  <h5 class="text-muted">Withdrawal Records</h5>
                </router-link>
              </div>
              <div class="col-md-4 col-6 text-center">
                <router-link to="/investment-history">
                  <div class="avatar-sm rounded-circle bg-icon-warning ml-auto mr-auto">
                    <i class="mdi mdi-chat-alert font-24 avatar-title text-white"></i>
                  </div>
                  <h5 class="text-muted">Investment Records</h5>
                </router-link>
              </div>
              <div class="col-md-4 col-6 text-center">
                <div class="avatar-sm rounded-circle bg-icon-purple ml-auto mr-auto">
                  <i class="mdi mdi-chat-alert font-24 avatar-title text-white"></i>
                </div>
                <h5 class="text-muted">Invite your friends</h5>
              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-6 offset-3">
                <button class="btn btn-sm btn-outline-success btn-block mt-2"><i class="fa fa-arrow-down"/> Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>
import WidgetChart from "/src/views/pages/dashboard/analytics/components/Widget-chart";
import RevenueReport from "/src/views/pages/dashboard/analytics/components/Revenue-report";
import ProductsSales from "/src/views/pages/dashboard/analytics/components/Products-sales";
import MarketingReports from "/src/views/pages/dashboard/analytics/components/Marketing-reports";
import Portlet from "/src/views/pages/dashboard/analytics/components/Portlet";
import RevenueHistory from "/src/views/pages/dashboard/analytics/components/Revenue-history";
import Projections from "/src/views/pages/dashboard/analytics/components/Projections";


export default {
  name: "index",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")

  },
  data() {
    return {
      account: '',
      total_asset:''
    }
  },
  methods: {
    convertTo2dp(value) {
      return value ? (Math.round(value * 100) / 100).toFixed(2) : '';
    },
    getUserAccountInfo() {
      this.accountLoader = true;
      this.$store.dispatch('getUserAccountDetails').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.account = extra;
          this.total_asset = Number(this.account.investors_account.wallet_total_balance) + Number(this.account.investors_account.referral_total_balance)
        }
      }).finally(() => {
        this.accountLoader = false;
      })
    }

  },
  created() {
    this.getUserAccountInfo();
  }

}
</script>

<style scoped>

</style>